import { SelectOption } from '@nkc-frontend/cat-design/dist/components/List';
import { observer } from 'mobx-react-lite';
import { FC, ReactNode } from 'react';
import { CatalogSelect } from 'src/applications/base/app/react-app/pages/catalog/catalog-sidebar/CatalogSidebar/CatalogSelect/CatalogSelect';
import classes from './CatalogSearch.module.scss';
import { SearchInput } from '@nkc-frontend/cat-design';
import classNames from 'classnames';
import {
    Catalog,
    CatalogItemFilterTree,
} from 'src/applications/base/types/catalog/catalog';

export interface CatalogSearchProps {
    searchValue: string;
    onSearch: (value: string) => void;
    activeCatalogList: string[];
    onFilter: (filters: string[]) => void;
    catalogs: Catalog[] | undefined;
    showFilters?: boolean;
    className?: string;
    afterSearchInput?: ReactNode;
    itemTree: CatalogItemFilterTree | undefined;
}

export const CatalogSearch: FC<CatalogSearchProps> = observer((props) => {
    const {
        searchValue,
        onSearch,
        activeCatalogList,
        onFilter,
        className,
        showFilters,
        catalogs,
        afterSearchInput,
        itemTree,
    } = props;

    return (
        <>
            <div className={classNames(classes['catalog-search'], className)}>
                <div className={classes['catalog-search-input']}>
                    <SearchInput
                        value={searchValue}
                        onChange={(event) =>
                            onSearch(event.target.value.toLowerCase())
                        }
                        data-testid={'search-catalog-input'}
                        placeholder={'Поиск'}
                        visualStyle={'plain'}
                        onClear={() => onSearch('')}
                    />
                    {searchValue.length > 2 &&
                        !Object.keys(itemTree?.items || {}).length && (
                            <span
                                className={
                                    classes['catalog-search-danger-message']
                                }
                            >
                                Категории не найдены. Попробуйте изменить
                                запрос.
                            </span>
                        )}
                </div>
                {afterSearchInput && (
                    <div className={classes['catalog-search-tools']}>
                        {afterSearchInput}
                    </div>
                )}
            </div>
            {showFilters && (
                <CatalogSelect
                    className={classes['catalog-type-select']}
                    activeCatalogList={activeCatalogList}
                    catalogs={catalogs}
                    onSelect={(option: SelectOption) =>
                        onFilter([...activeCatalogList, option.value])
                    }
                    onDeselect={(option: SelectOption) =>
                        onFilter(
                            activeCatalogList.filter(
                                (catalogType) => catalogType !== option.value
                            )
                        )
                    }
                    onClear={() => onFilter([])}
                />
            )}
        </>
    );
});
