import { FC, useEffect } from 'react';
import classNames from 'classnames';

import classes from './CatalogItem.module.scss';
import { CatalogItemHeader } from 'src/applications/base/app/react-app/pages/catalog/catalog-item/CatalogItemHeader';
import { Keywords } from 'src/applications/base/app/react-app/pages/catalog/catalog-item/Keywords';
import { observer } from 'mobx-react-lite';
import { CatalogDocumentStoreType } from 'src/applications/base/types/catalog/catalog-document';
import { DocumentStoreType } from 'src/applications/base/types/document/documents';
import { CatalogDocuments } from 'src/applications/base/app/react-app/pages/catalog/catalog-item/CatalogDocuments';
import {
    CatalogItemsStoreType,
    CatalogsStoreType,
} from 'src/applications/base/types/catalog/catalog';

export interface CatalogItemProps {
    catalogStore: CatalogsStoreType;
    catalogDocuments: CatalogDocumentStoreType;
    documents: DocumentStoreType;
    catalogId: string | undefined;
    catalogItemId: string | undefined;
    catalogItemsStore: CatalogItemsStoreType;
    className?: string;
}

export const CatalogItem: FC<CatalogItemProps> = observer((props) => {
    const {
        className,
        catalogItemId,
        catalogId,
        catalogItemsStore,
        documents,
        catalogDocuments,
        catalogStore,
    } = props;

    useEffect(() => {
        if (
            catalogId &&
            catalogItemId &&
            catalogItemsStore.getItem(catalogItemId) == null
        ) {
            catalogItemsStore.fetchItem(catalogItemId);
        }
    }, [catalogId, catalogItemId, catalogItemsStore]);

    const catalogItem =
        catalogId && catalogItemId
            ? catalogItemsStore.getItem(catalogItemId)
            : undefined;

    const itemFilterTree = catalogId
        ? catalogItemsStore.getItemTree(catalogId)
        : undefined;

    const keywords = catalogItem?.keywords;

    return (
        <section className={classNames(classes['catalog-item'], className)}>
            <div className={classes['heading-container']}>
                <div className={classes.heading}>
                    <CatalogItemHeader
                        catalogStore={catalogStore}
                        itemTree={itemFilterTree}
                        catalogId={catalogId}
                        catalogItemId={catalogItemId}
                    />
                </div>
            </div>
            <div className={classes.body}>
                {keywords && keywords.length > 0 && (
                    <Keywords
                        className={classes.keywords}
                        keywords={keywords}
                    />
                )}
                {catalogItemId && (
                    <div className={classes.documents}>
                        <CatalogDocuments
                            documents={documents}
                            catalogDocuments={catalogDocuments}
                            catalogItemId={catalogItemId}
                        />
                    </div>
                )}
            </div>
        </section>
    );
});
