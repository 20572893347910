import React from 'react';
import { IBreadCrumbsProps } from './types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import classes from './BreadCrumbs.module.scss';

export const BreadCrumbs: React.FC<IBreadCrumbsProps> = (props) => {
    const delimiter = props.delimiter || '/';
    const delimiterClassname = props.delimiterClassName || classes.delimiter;
    const pathsToRender = props.shouldReversePaths
        ? props.paths.slice().reverse()
        : props.paths;

    return (
        <div className={classNames(props.className, classes.breadcrumbs)}>
            {pathsToRender.map((path, index) => (
                <span key={path.link || index}>
                    {index > 0 && (
                        <span className={delimiterClassname}>{delimiter}</span>
                    )}
                    {path.link ? (
                        <Link
                            data-testid={'admin-breadcrumbs-link'}
                            className={classNames(
                                props.pathClassName,
                                classes.link
                            )}
                            to={path.link}
                            title={path.title}
                        >
                            {path.title}
                        </Link>
                    ) : (
                        <span
                            className={classNames(
                                props.pathClassName,
                                classes.link
                            )}
                        >
                            {path.title}
                        </span>
                    )}
                </span>
            ))}
        </div>
    );
};
