import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import classes from 'src/applications/base/app/react-app/pages/catalog/catalog-item/Keywords/Keywords.module.scss';

export interface KeywordsProps {
    className?: string;
    keywords?: string[];
}

export const Keywords: FC<KeywordsProps> = observer((props) => {
    const { className, keywords } = props;

    return (
        <div className={className}>
            <div className={classes['title']}>Ключевые слова</div>
            <div className={classes['content']}>{keywords?.join(', ')}</div>
        </div>
    );
});
