import { Button, Icon, List } from '@nkc-frontend/cat-design';
import { SelectOption } from '@nkc-frontend/cat-design/dist/components/List';
import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import classes from './CatalogSelect.module.scss';
import { Catalog } from 'src/applications/base/types/catalog/catalog';

export interface CatalogSelectProps {
    className?: string;
    activeCatalogList: string[];
    onSelect: (catalogType: SelectOption) => void;
    onDeselect: (catalogType: SelectOption) => void;
    catalogs: Catalog[] | undefined;
    onClear(): void;
}

export const CatalogSelect: FC<CatalogSelectProps> = observer((props) => {
    const {
        activeCatalogList,
        onSelect,
        onDeselect,
        onClear,
        className,
        catalogs = [],
    } = props;

    const catalogOptions: SelectOption[] = useMemo(
        () => catalogs.map((cat) => ({ label: cat.title, value: cat.id })),
        [catalogs]
    );

    return catalogOptions.length > 1 ? (
        <div className={className}>
            <div className={classes.header}>
                <div className={classes.title}>Фильтр по каталогам</div>
                {activeCatalogList.length > 0 && (
                    <Button
                        className={classes['clear-btn']}
                        before={<Icon icon={'close'} size={'XS'} />}
                        data-testid={'catalog-drop-filters-btn'}
                        visualStyle={'plain-link'}
                        onClick={onClear}
                    >
                        Сбросить
                    </Button>
                )}
            </div>
            <Scrollbars
                style={{
                    height: 54,
                }}
            >
                <div data-testid={'catalog-type-filter-list'}>
                    <List
                        options={catalogOptions}
                        active={activeCatalogList}
                        onSelect={onSelect}
                        onDeselect={onDeselect}
                    />
                </div>
            </Scrollbars>
        </div>
    ) : null;
});
