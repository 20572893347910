import { observer } from 'mobx-react-lite';
import { FC, useCallback } from 'react';
import { CatalogItemFilterTree } from 'src/applications/base/types/catalog/catalog';
import { CatalogItemTreeNode } from '../CatalogItemTreeNode';
import { CatalogRoute } from 'src/applications/base/types/routing';
import { COMMON_DEP } from 'src/applications/base/inversify/dependencyIdentifiers';
import { useService } from '@redtea/react-inversify';
import { CatalogItemTreeNodeProps } from 'src/applications/base/app/react-app/pages/catalog/catalog-sidebar/CatalogSidebar/CatalogItemTree/CatalogItemTreeNode/CatalogItemTreeNode';
import { Scrollbars } from 'react-custom-scrollbars';
import { Button } from '@nkc-frontend/cat-design';

import classes from './CatalogItemTree.module.scss';

export interface CatalogItemTreeProps {
    itemTree: CatalogItemFilterTree | undefined;
    catalogId?: string;
    catalogItemId: string | undefined;
    loadMore(parentCatalogItemId?: string): void;
    isToggleUpAll?: boolean;
    catalogItemSearchQuery?: string;
}

const INITIAL_PADDING = 32;
const ITEM_PADDING = 16;

export const CatalogItemTree: FC<CatalogItemTreeProps> = observer((props) => {
    const {
        itemTree,
        catalogId,
        catalogItemId,
        loadMore,
        isToggleUpAll,
        catalogItemSearchQuery,
    } = props;

    const { catalog: catalogRouting } = useService<CatalogRoute>(
        COMMON_DEP.ClientRouting
    );

    const getCatalogItemUrl = useCallback(
        ({ catalogItemId, catalogTree }: CatalogItemTreeNodeProps) => {
            const item = catalogTree.items[catalogItemId];
            if (!item) {
                return '#';
            }
            return catalogRouting.getUrl({
                catalogId: catalogId,
                itemId: catalogItemId,
            });
        },
        [catalogId, catalogRouting]
    );

    const isItemSelected = useCallback(
        (props: CatalogItemTreeNodeProps) => {
            return props.catalogItemId === catalogItemId;
        },
        [catalogItemId]
    );

    const getPadding = useCallback(
        ({ depthLevel }: CatalogItemTreeNodeProps) => {
            return INITIAL_PADDING + (depthLevel ?? 0) * ITEM_PADDING;
        },
        []
    );

    return (
        <div className={classes.wrapper}>
            {itemTree && (
                <Scrollbars
                    style={{
                        height: '100%',
                    }}
                >
                    {itemTree.top.map((itemId) => (
                        <CatalogItemTreeNode
                            key={itemId}
                            to={getCatalogItemUrl}
                            catalogItemId={itemId}
                            catalogTree={itemTree}
                            padding={getPadding}
                            selected={isItemSelected}
                            loadMore={loadMore}
                            isToggleUpAll={isToggleUpAll}
                            catalogItemSearchQuery={catalogItemSearchQuery}
                        />
                    ))}
                    {itemTree.top.length > 0 &&
                        itemTree.top.length < itemTree.total && (
                            <div
                                style={{
                                    paddingLeft: INITIAL_PADDING,
                                }}
                            >
                                <Button
                                    className={classes.more}
                                    visualStyle={'plain-link'}
                                    onClick={() => loadMore()}
                                >
                                    Показать ещё
                                </Button>
                            </div>
                        )}
                </Scrollbars>
            )}
        </div>
    );
});
