import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { CatalogRoute } from 'src/applications/base/types/routing';
import classes from './CatalogItemHeader.module.scss';
import { BreadCrumbs } from '../../../../components/common/admin/BreadCrumbs';
import { isValueNotEmpty } from '../../../../../utils/isValueNotEmpty';
import { useService } from '@redtea/react-inversify';
import { COMMON_DEP } from '../../../../../../inversify/dependencyIdentifiers';
import { Icon } from '@nkc-frontend/cat-design';
import {
    CatalogItemFilterTree,
    CatalogsStoreType,
} from 'src/applications/base/types/catalog/catalog';

export interface CatalogItemHeaderProps {
    catalogStore: CatalogsStoreType;
    catalogId: string | undefined;
    catalogItemId: string | undefined;
    itemTree?: CatalogItemFilterTree | null;
    className?: string;
}

export const CatalogItemHeader: FC<CatalogItemHeaderProps> = observer(
    (props) => {
        const { catalogId, className, itemTree, catalogItemId, catalogStore } =
            props;
        const { catalog: catalogRouting }: CatalogRoute = useService(
            COMMON_DEP.ClientRouting
        );

        function constructPath(
            _itemTree: CatalogItemFilterTree,
            _catalogItemId: string,
            acc: string[]
        ): string[] {
            const parentId = _itemTree.items[_catalogItemId].parentCode;

            if (parentId) {
                acc.push(_itemTree.items[parentId].id);
                return constructPath(_itemTree, parentId, acc);
            } else {
                return acc;
            }
        }

        function getBreadCrumbs(
            _itemTree: CatalogItemFilterTree | null | undefined,
            _catalogItemId: string | undefined
        ) {
            if (
                _itemTree &&
                _catalogItemId &&
                _itemTree.items[_catalogItemId]
            ) {
                const currentPathPoint = [_itemTree.items[_catalogItemId].id];
                const path = constructPath(
                    _itemTree,
                    _catalogItemId,
                    currentPathPoint
                );

                return path?.map((id) => {
                    const title = _itemTree?.items[id].title;
                    return {
                        title: title?.charAt(0).toUpperCase() + title?.slice(1),
                        link: catalogRouting.getUrl({ catalogId, itemId: id }),
                    };
                });
            } else {
                return [];
            }
        }
        const breadCrumbs = getBreadCrumbs(itemTree, catalogItemId);

        //самым последним добавляем текущий каталог
        const catalog = catalogId && catalogStore.getCatalog(catalogId);
        if (catalog) {
            breadCrumbs?.push({
                title: catalog.title,
                link: '#',
            });
        }

        return (
            <div className={className}>
                {breadCrumbs && (
                    <BreadCrumbs
                        className={classes['breadcrumbs']}
                        delimiterClassName={classes['delimiter']}
                        pathClassName={classes['path']}
                        delimiter={
                            <Icon icon='arrow' rotate={-90} size={'M'} />
                        }
                        paths={breadCrumbs.filter(isValueNotEmpty)}
                        shouldReversePaths
                    />
                )}
            </div>
        );
    }
);
