import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { CatalogList } from './CatalogList/CatalogList';
import { CatalogSearch } from './CatalogSearch/CatalogSearch';
import { CatalogItemTree } from './CatalogItemTree/Tree';
import throttle from 'lodash/throttle';

import classes from './CatalogSidebar.module.scss';
import { useHistory } from 'react-router-dom';
import { useService } from '@redtea/react-inversify';
import { COMMON_DEP } from 'src/applications/base/inversify/dependencyIdentifiers';
import { CatalogRoute } from 'src/applications/base/types/routing';
import {
    CatalogItemsStoreType,
    CatalogsStoreType,
} from 'src/applications/base/types/catalog/catalog';
import SplitPane from 'react-split-pane';
import { timer } from 'rxjs';
import { Button } from '@nkc-frontend/cat-design';
import IconSprite from '../../../../components/common/IconSprite/IconSprite';

export interface CatalogSidebarProps {
    catalogStore: CatalogsStoreType;
    catalogItemsStore: CatalogItemsStoreType;
    className?: string;
    catalogId?: string;
    catalogItemId?: string;
}

const INITIAL_ACTIVE_CATALOG_FILTER: string[] = [];

export const CatalogSidebar: FC<CatalogSidebarProps> = observer((props) => {
    const {
        className,
        catalogId,
        catalogStore,
        catalogItemsStore,
        catalogItemId,
    } = props;
    const history = useHistory();
    const { catalog: catalogRouting } = useService<CatalogRoute>(
        COMMON_DEP.ClientRouting
    );

    const [catalogItemSearchQuery, setCatalogItemSearchQuery] =
        useState<string>('');
    const [activeCatalogFilter, setActiveCatalogFilter] = useState<string[]>(
        INITIAL_ACTIVE_CATALOG_FILTER
    );
    const [toggleUp, setToggleUp] = useState<boolean>(false);

    const hasItemSearchQuery = !!catalogItemSearchQuery.trim();

    const throttledSearchItems = useMemo(
        () =>
            throttle(
                (query: string, catalogs?: string[]) =>
                    catalogItemsStore.searchCatalogItems(query, catalogs),
                400,
                {
                    leading: false,
                    trailing: true,
                }
            ),
        [catalogItemsStore]
    );

    useEffect(() => {
        catalogStore.fetchCatalogList();
    }, [catalogStore]);

    useEffect(() => {
        if (catalogId) {
            catalogItemsStore.fetchCatalogItemsList(
                catalogId,
                undefined,
                false
            );
        }
    }, [catalogItemsStore, catalogId]);

    useEffect(() => {
        catalogItemsStore.cancelCatalogSearchItems();
        catalogItemsStore.clearFoundCatalogItems();

        const query = catalogItemSearchQuery.trim();
        if (query) {
            throttledSearchItems(query, activeCatalogFilter);
        }
    }, [
        catalogItemSearchQuery,
        catalogItemsStore,
        activeCatalogFilter,
        throttledSearchItems,
    ]);

    useEffect(() => {
        if (!hasItemSearchQuery) {
            setActiveCatalogFilter(INITIAL_ACTIVE_CATALOG_FILTER);
        }
    }, [hasItemSearchQuery]);

    const onSetActiveCatalog = useCallback(
        (nextCatalogId?: string) => {
            if (nextCatalogId && nextCatalogId !== catalogId) {
                history.push(
                    catalogRouting.getUrl({
                        catalogId: nextCatalogId,
                        itemId: undefined,
                    })
                );
            }
        },
        [history, catalogRouting, catalogId]
    );

    const loadMoreCatalogItems = useCallback(
        (parentCatalogItemId?: string) => {
            if (catalogItemSearchQuery) {
                catalogItemsStore.searchCatalogItems(
                    catalogItemSearchQuery.trim(),
                    activeCatalogFilter
                );
            } else if (catalogId) {
                catalogItemsStore.fetchCatalogItemsList(
                    catalogId,
                    parentCatalogItemId,
                    true
                );
            }
        },
        [
            catalogItemSearchQuery,
            activeCatalogFilter,
            catalogId,
            catalogItemsStore,
        ]
    );

    const catalogList = catalogStore.getCatalogList();

    const catalogItemTree = !!catalogId
        ? catalogItemsStore.getItemTree(catalogId)
        : undefined;
    const foundItemsTree = catalogItemsStore.getFoundCatalogItems();

    const usedItemTree = hasItemSearchQuery ? foundItemsTree : catalogItemTree;

    const minSize = useMemo<number>(() => {
        return !hasItemSearchQuery ? 120 : 100;
    }, [hasItemSearchQuery]);

    const handlerToggleUpAll = (): void => {
        setToggleUp(true);
        timer(100).subscribe((_) => setToggleUp(false));
    };

    const defaultSize = useMemo<number>(() => {
        let defaultSize = 82;
        if (catalogList?.length) {
            defaultSize +=
                (catalogList.length > 3 ? 3 : catalogList.length) * 46;
        }
        return defaultSize;
    }, [catalogList?.length]);

    return (
        <div className={className}>
            <SplitPane
                defaultSize={defaultSize}
                minSize={minSize}
                pane1Style={{ maxHeight: 'calc(100% - 150px)' }}
                split='horizontal'
            >
                <div className={classes['sidebar-header']}>
                    <div className={classes['sidebar-title']}>Каталоги</div>
                    {!hasItemSearchQuery && (
                        <CatalogList
                            catalogs={catalogList ?? []}
                            activeCatalogId={catalogId}
                            onSelect={onSetActiveCatalog}
                        />
                    )}
                </div>

                <div className={classes['sidebar-subtree-panel']}>
                    <CatalogSearch
                        className={classes.search}
                        catalogs={catalogList ?? undefined}
                        searchValue={catalogItemSearchQuery}
                        onSearch={setCatalogItemSearchQuery}
                        activeCatalogList={activeCatalogFilter}
                        onFilter={setActiveCatalogFilter}
                        showFilters={hasItemSearchQuery}
                        itemTree={usedItemTree ?? undefined}
                        afterSearchInput={
                            <Button
                                visualStyle={'plain-link'}
                                onClick={handlerToggleUpAll}
                            >
                                <IconSprite name={'arrow-collapse'} size={16} />
                            </Button>
                        }
                    />

                    <CatalogItemTree
                        itemTree={usedItemTree ?? undefined}
                        catalogItemId={catalogItemId}
                        loadMore={loadMoreCatalogItems}
                        catalogId={catalogId}
                        isToggleUpAll={toggleUp}
                        catalogItemSearchQuery={catalogItemSearchQuery}
                    />
                </div>
            </SplitPane>
        </div>
    );
});
