import { FC, useEffect, useRef } from 'react';
import useToggle from 'react-use/lib/useToggle';
import { CatalogItemFilterTree } from 'src/applications/base/types/catalog/catalog';
import classNames from 'classnames';
import { Button, Icon } from '@nkc-frontend/cat-design';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import classes from './CatalogItemTreeNode.module.scss';

export interface CatalogItemTreeNodeProps {
    catalogTree: CatalogItemFilterTree;
    catalogItemId: string;
    to(props: CatalogItemTreeNodeProps): string;
    loadMore(catalogItemId: string): void;
    className?: string;
    selected?(props: CatalogItemTreeNodeProps): boolean;
    depthLevel?: number;
    initiallyOpenedLevel?: number;
    padding?: number | ((props: CatalogItemTreeNodeProps) => number);
    isToggleUpAll?: boolean;
    catalogItemSearchQuery?: string;
}

export const CatalogItemTreeNode: FC<CatalogItemTreeNodeProps> = observer(
    (props) => {
        const {
            catalogTree,
            catalogItemId,
            className,
            depthLevel = 0,
            padding = 0,
            initiallyOpenedLevel = -1,
            selected,
            to,
            loadMore,
            isToggleUpAll,
            catalogItemSearchQuery,
        } = props;

        const [isOpened, toggleIsOpened] = useToggle(
            depthLevel <= initiallyOpenedLevel
        );

        const catalogItem = catalogTree.items[catalogItemId];

        const wasInitiallyLoaded = useRef(false);
        useEffect(() => {
            if (isOpened && !wasInitiallyLoaded.current) {
                loadMore(catalogItemId);
                wasInitiallyLoaded.current = true;
            }
        }, [isOpened, loadMore, catalogItemId]);
        useEffect(() => {
            if (isToggleUpAll) {
                toggleIsOpened(false);
            }
        }, [isToggleUpAll, toggleIsOpened]);

        if (!catalogItem) {
            return null;
        }

        const childrenCount = catalogItem.childrenCount;

        const paddingLeft =
            typeof padding === 'function'
                ? padding(props)
                : padding * depthLevel;

        const loadMorePaddingLeft =
            typeof padding === 'function'
                ? padding({ ...props, depthLevel: (props.depthLevel ?? 0) + 1 })
                : padding * (depthLevel + 1);
        const regExpSearchResult = new RegExp(
            `(${catalogItemSearchQuery})`,
            'ig'
        );

        return (
            <>
                <div
                    className={classNames(className, classes['catalog-item'], {
                        [classes['catalog-item_selected']]: selected?.(props),
                    })}
                    style={{ paddingLeft }}
                >
                    {childrenCount && childrenCount > 0 ? (
                        <Button
                            visualStyle={'plain'}
                            className={classes.toggle}
                            onClick={() => {
                                toggleIsOpened();
                            }}
                            after={
                                <Icon
                                    className={classes.arrow}
                                    icon={'arrow'}
                                    rotate={!isOpened ? -90 : 0}
                                    size={'S'}
                                />
                            }
                        />
                    ) : undefined}
                    <Link
                        to={to(props)}
                        className={classNames(classes.link, {
                            [classes['link_no-children']]:
                                !catalogItem.childrenCount,
                        })}
                    >
                        <div
                            className={classes.title}
                            dangerouslySetInnerHTML={{
                                __html: catalogItem.title.replace(
                                    regExpSearchResult,
                                    '<b>$1</b>'
                                ),
                            }}
                        />
                        <div className={classes.count}>
                            {catalogItem.docCount}
                        </div>
                    </Link>
                </div>
                {isOpened &&
                    catalogItem.children?.map((childItemId) => (
                        <CatalogItemTreeNode
                            key={childItemId}
                            catalogTree={catalogTree}
                            catalogItemId={childItemId}
                            to={to}
                            className={className}
                            padding={padding}
                            depthLevel={depthLevel + 1}
                            initiallyOpenedLevel={initiallyOpenedLevel}
                            selected={selected}
                            loadMore={loadMore}
                        />
                    ))}
                {isOpened &&
                catalogItem.children &&
                childrenCount &&
                catalogItem.children.length < childrenCount ? (
                    <div style={{ paddingLeft: loadMorePaddingLeft }}>
                        <Button
                            className={classes.more}
                            visualStyle={'plain-link'}
                            onClick={() => loadMore(catalogItemId)}
                        >
                            Показать ещё
                        </Button>
                    </div>
                ) : undefined}
            </>
        );
    }
);
