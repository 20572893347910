import { FC, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
    CatalogParams,
    CatalogRoute,
} from 'src/applications/base/types/routing';
import { CatalogItem } from 'src/applications/base/app/react-app/pages/catalog/catalog-item/CatalogItem';

import { CatalogSidebar } from 'src/applications/base/app/react-app/pages/catalog/catalog-sidebar/CatalogSidebar';
import { useStores } from 'src/utils/mobx-store-utils/useStores';
import { useUnmount } from 'react-use';
import {
    CatalogDocumentSlice,
    CatalogsAndItemsSlice,
    DocumentSlice,
} from 'src/applications/base/types/stores';

import classes from './Catalog.module.scss';
import './Catalog-FA-icon.scss';
import { observer } from 'mobx-react-lite';
import { COMMON_DEP } from 'src/applications/base/inversify/dependencyIdentifiers';
import { useService } from '@redtea/react-inversify';
import { useComputed } from 'src/applications/base/app/react-app/hooks/useComputed';
import SplitPane from 'react-split-pane';

export type CatalogProps = RouteComponentProps<CatalogParams>;

export const Catalog: FC<CatalogProps> = observer((props) => {
    const { match, history } = props;
    const { catalogs, catalogItems, documents, catalogDocuments } = useStores<
        CatalogsAndItemsSlice & DocumentSlice & CatalogDocumentSlice
    >();
    const { catalog: catalogRouting } = useService<CatalogRoute>(
        COMMON_DEP.ClientRouting
    );

    const catalogId = match.params.catalogId;
    const itemId = match.params.itemId;

    //вычисляем id первого в списке каталога
    const firstCatalogId = useComputed(
        () => catalogs.getCatalogList()?.[0]?.id,
        [catalogs]
    );
    //вычисляем id первого в списке элемента каталога
    const firstCatalogItemId = useComputed(() => {
        if (firstCatalogId) {
            const tree = catalogItems.getItemTree(firstCatalogId);
            if (tree) {
                return tree.items[tree.top[0]]?.id;
            }
        }
    }, [catalogItems, firstCatalogId]);
    //если не выбран никакой каталог, подгружаем список элементов
    //первого каталога
    useEffect(() => {
        if (!catalogId && firstCatalogId) {
            catalogItems.fetchCatalogItemsList(firstCatalogId);
        }
    }, [catalogId, firstCatalogId, catalogItems]);
    //если не выбран никакой каталог и элемент, то автоматически выбираем
    //первый элемент в первом каталоге
    useEffect(() => {
        const nothingChosen = !catalogId && !itemId;
        const hasFirstCatalogAndItemIds =
            !!firstCatalogId && !!firstCatalogItemId;
        if (nothingChosen && hasFirstCatalogAndItemIds) {
            //делаем replace, чтобы юзер не мог кнопкой назад опять попасть
            //в состояние, когда ничего не выбрано
            history.replace(
                catalogRouting.getUrl({
                    catalogId: firstCatalogId,
                    itemId: firstCatalogItemId,
                })
            );
        }
    }, [
        catalogId,
        itemId,
        firstCatalogId,
        firstCatalogItemId,
        history,
        catalogRouting,
    ]);

    //подчищаем всё после анмаунта
    useUnmount(() => catalogItems.clearUp());

    // вывод tooltip для разделителя
    const resizer = document.querySelector('.Resizer.vertical');
    const tooltip = document.createElement('div');
    tooltip.classList.add('tooltip');
    document.body.appendChild(tooltip);

    let tooltipText = '';
    let tooltipTimer: ReturnType<typeof setTimeout> | null = null;

    resizer?.addEventListener('mousemove', (event: any) => {
        tooltipText = 'Перетащите, чтобы изменить размер';
        if (!tooltipTimer) {
            tooltipTimer = setTimeout(() => {
                const computedStyles: any = getComputedStyle(resizer);
                const boxShadowOffset = 5; // смещение тени для подсказки
                const boxShadowValue = parseInt(computedStyles.boxShadow, 10);
                const effectiveShadowOffset = isNaN(boxShadowValue)
                    ? boxShadowOffset
                    : boxShadowValue + boxShadowOffset;

                tooltip.style.display = 'block';
                tooltip.innerText = tooltipText;
                tooltip.style.left = `${event.clientX}px`;
                tooltip.style.top = `${
                    event.clientY + effectiveShadowOffset - 20
                }px`;
                tooltipTimer = null;
            }, 100);
        }
    });

    resizer?.addEventListener('mouseout', () => {
        if (tooltipTimer) {
            clearTimeout(tooltipTimer);
            tooltipTimer = null;
        }
        tooltip.style.display = 'none';
    });

    return (
        <SplitPane
            defaultSize={560}
            maxSize={780}
            minSize={280}
            split='vertical'
            className={classes.catalog}
        >
            <CatalogSidebar
                catalogStore={catalogs}
                catalogItemsStore={catalogItems}
                className={classes.sidebar}
                catalogId={catalogId}
                catalogItemId={itemId}
            />
            <CatalogItem
                catalogStore={catalogs}
                catalogItemsStore={catalogItems}
                className={classes.item}
                catalogItemId={itemId}
                catalogId={catalogId}
                documents={documents}
                catalogDocuments={catalogDocuments}
            />
        </SplitPane>
    );
});
