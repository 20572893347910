import { Button, Icon, List } from '@nkc-frontend/cat-design';
import { SelectOption } from '@nkc-frontend/cat-design/dist/components/List';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Catalog } from 'src/applications/base/types/catalog/catalog';
import classes from './CatalogList.module.scss';

export interface CatalogsListProps {
    catalogs: Catalog[];
    activeCatalogId?: string;
    onSelect: (catalogId?: string) => void;
}

export interface CatalogListOptionProps {
    active: boolean;
    option: SelectOption;
    onSelect(option: SelectOption): void;
}

export const CatalogListOption: FC<CatalogListOptionProps> = (props) => {
    const { active, option, onSelect } = props;

    return (
        <Button
            className={classes['catalog-option']}
            data-testid={'catalog-option'}
            visualStyle={'plain'}
            before={
                <Icon
                    size={'M'}
                    icon='checked'
                    hidden={!active}
                    className={classes['catalog-option-check-icon']}
                />
            }
            onClick={() => onSelect(option)}
        >
            {option.label}
        </Button>
    );
};

export const CatalogList: FC<CatalogsListProps> = observer((props) => {
    const { catalogs, activeCatalogId, onSelect } = props;

    const catalogsListOptions: SelectOption[] = catalogs.map((catalog) => {
        return { label: catalog.title, value: catalog.id, key: catalog.id };
    });

    return (
        <div className={classes['catalog-list']}>
            <Scrollbars
                style={{
                    height: '100%',
                }}
            >
                <div data-testid={'catalog-options-list'}>
                    <List
                        options={catalogsListOptions}
                        active={activeCatalogId ? [activeCatalogId] : []}
                        onSelect={(option: SelectOption) =>
                            onSelect(option.value)
                        }
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onDeselect={() => {}}
                        OptionComponent={CatalogListOption}
                    />
                </div>
            </Scrollbars>
        </div>
    );
});
